@use "@ckmk/angular/mixins";

.front-container {
  @apply
    /* From phone */
  block w-full mx-auto px-4
    /* From tablette */
  md:max-w-[85%] md:px-0
  ;
}

.card-home-item {

  @include mixins.defaultBody;

  & {
    transition: .3s;
    z-index: 5;
    width: 100%;
  }

  @apply
  flex flex-col gap-y-3 bg-white overflow-hidden !py-6 !px-4
  md:cursor-context-menu
  ;

  .card-home-item-img {
    @apply
    w-full block relative z-[2]
    ;
  }

  .card-home-item-title {
    @apply
    w-full block font-bold text-3xl relative z-[2]
    ;
  }

  .card-home-item-date {
    @apply
    text-[12px]
    ;
  }

  .card-home-item-description {
    @apply
    relative z-[2]
    ;
  }

  .card-home-item-button {
    @apply
    relative z-[2]
    ;
  }

  .card-home-item-see-more {
    @apply
    relative z-[2]
    flex w-full items-center justify-center
    ;

    a {
      @apply
      inline-flex items-center justify-center gap-x-3 border-b-[2px] border-b-app-text-800 pb-[1px]
      ;
    }


    span {
      @apply
      font-bold
      ;
    }
  }

  .card-home-item-headband {
    @apply
    absolute inset-0 bg-app-color-50 rotate-[30deg] translate-x-[70%] scale-125
    ;
  }


  // Includes
  @include mixins.active {
    box-shadow: 0 0 14px 5px rgba(black, 0.07);
    z-index: 10;
  }
  @include mixins.tabletView {
    width: calc((100% / 2) - (30px / 2));
  }
  @include mixins.computerView(1300px) {
    width: calc((100% / 3) - (60px / 3));
  }
  // Includes
}

.picture-text-container {
  @apply
  flex w-full flex-col gap-[30px] items-start
  lg:flex-row
  ;

  .picture {
    @apply
    block rounded w-full
    lg:max-w-[40%]
    ;
  }

  .text-content {
    @apply
    flex w-full flex-col gap-[10px]
    ;
  }

  &.reverse {
    @apply
    lg:flex-row-reverse
    ;
  }
}

.cards-container {
  @apply
  flex w-full flex-row gap-[30px] flex-wrap
  ;
}
