@use 'mixins';

@import 'cropperjs/src/index.css';


// Refactored at 2024-12-01

* {

  // New_Includes
  @include mixins.fontNunito;
  // New_Includes

  & {
    color: var(--color-txt);
    font-size: var(--font-size);
    box-sizing: border-box;
    position: relative;
  }
}

fa-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  * {
    pointer-events: none;
  }
}

.ck.ck-balloon-panel.ck-balloon-panel_position_border-side_right.ck-powered-by-balloon,
.ck.ck-balloon-panel.ck-balloon-panel_position_border-side_left.ck-balloon-panel_visible.ck-evaluation-badge-balloon
{
  display: none !important;
}

.field-loading-items {
  // New_Includes
  @include mixins.threePoint {
    padding: 0;
  }
  // New_Includes

  & {
    user-select: none;
    cursor: wait;
    color: var(--color-txt);
  }

}

.cdk-global-overlay-wrapper {

}

.cdk-overlay-pane.mat-mdc-dialog-panel {
  width: max-content !important;
  max-width: 90% !important;
}
