:root {
  --text-color: #494949;

  --select-bg: #000000;
  --select-color: #c1954b;
}

[data-theme="dark"] {

  :root {
    --text-color: #494949;

    --select-bg: #000000;
    --select-color: #c1954b;
  }
}
