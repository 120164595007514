:root {
  // The modal variable
  --modal-backdrop-background-color: rgba(0, 0, 0, .6);
  --modal-border-radius: 3px;
  --modal-padding: 15px;
  --modal-title-size: 20px;
  --modal-sidebar-gap: 15px;
  --modal-footer-gap: 10px;
  --modal-bdr-bg-color: var(--color-primary);
  --modal-bdr-bg-color-hover: var(--color-primary-hover);
  --modal-text-color: var(--color-white);
}

[data-theme="dark"] {
  // The modal variable
  --modal-backdrop-background-color: rgba(0, 0, 0, .6);
  --modal-border-radius: 3px;
  --modal-padding: 15px;
  --modal-title-size: 20px;
  --modal-footer-gap: 10px;
  --modal-bdr-bg-color: #bac0c4;
  --modal-bdr-bg-color-hover: #9da1a5;
  --modal-text-color: var(--color-bg);
}
